.li {
  list-style: none;
}
a{
    text-decoration: none;
    color: #fff;
    font-size: 1.25rem;
}
a:hover{
    color: limegreen;
}
header{
    position: relative;
    padding: 0 2rem;
}
.navbar{
    width: 100%;
    height: 80px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo{
    font-size: 1.5rem;
    font-weight: bold;
}

.logo img{
    width: 100px;
    margin-top: 20px;
    margin-left: -30px;
}

.navbar .links{
    display: flex;
    gap: 2rem;
    list-style: none;
}

.navbar .toggle-btn{
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
}

.action-btn{
    background-color: limegreen;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    border-radius: 30px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.action-btn:hover{
    transform: scale(1.05);
    color: #fff;
}

.action-btn:active{
    transform: scale(0.95);
}

.dropdown-menu{
    display: none;
    position: absolute;
    right: 2rem;
    top: 80px;
    width: 240px;
    height: 0;
    background: #333;
    /* backdrop-filter: blur(15px); */
    border-radius: 10px;
    overflow: hidden;
    transition: height .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 100;
}

.dropdown-menu.open{
    height: 340px;
}

.dropdown-menu ul {
    list-style: none;
    padding: 0;
  }

.dropdown-menu li{
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-menu .action-btn{
    width: 100%;
    display: flex;
    justify-content: center;
}

@media(max-width: 992px){
    .navbar .links,.navbar .action-btn{
        display: none;
    }
    .navbar .toggle-btn{
        display: block;
    }
    .dropdown-menu{
        display: block;
        top: 80px;
    }
}