.projectcardBox {
    width: 300px;
    height: 300px;
    position: relative;
    display: grid;
    place-items: center;
    overflow: hidden;
    border-radius: 20px;
    border: 2px solid limegreen;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px,
      rgba(0, 0, 0, 0.5) 0px 2px 25px 0px;
  }
  
  .projectcard {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000814;
    border-radius: 20px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: limegreen;
    overflow: hidden;
    padding: 20px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
  }
  
  .projectcard .projectcontent p, .projectcard .projectcontent1 p {
    font-size: 1.1rem;
    line-height: 15px;
  }

  .projectcard img{
    width: 350px;
    height: 300px;
    object-fit: contain;
  }
  
  .projectcard .projectcontent {
    transform: translateY(100%);
    opacity: 0;
    margin-top: 10px;
    transition: 0.3s ease-in-out;
    display: flex; /* Add display flex */
  justify-content: space-between; /* Add space between icons */
  width: 100%; /* Add width */
  }
  
  .projectcard:hover .projectcontent {
    transform: translateY(0);
    opacity: 1;
  }