.certcardbox {
    width: 300px;
    height: 300px;
    position: relative;
    display: grid;
    place-items: center;
    overflow: hidden;
    border-radius: 20px;
    border: 1px solid limegreen;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px,
      rgba(0, 0, 0, 0.5) 0px 2px 25px 0px;
  }
  
  .certcard {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000814;
    border-radius: 20px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #ffffff;
    overflow: hidden;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
  }

  .certcard .certcontent p{
    font-size: 1.1rem;
    padding: 2px;
    color: limegreen;
  }

  .certcard img{
    width: 250px;
    height: 190px;
    object-fit: contain;
  }

  /* .certcard .certcontent {
    transform: translateY(-100%);
    opacity: 0;
    transition: 0.3s ease-in-out;
  }
  
  .certcard:hover .certcontent {
    transform: translateY(0);
    opacity: 1;
  } */