.home-container {
  color: #fff;
  padding: 50px;
  text-align: center;
  min-height: 81vh;
  position: relative;
}

.content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
  transition: opacity 1s ease;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%; /* Adjust for desktop screen */
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 1;
  transition: opacity 1s;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 0;
}

.hacker-title,
.hacker-text {
  font-size: 16px;
  margin-bottom: 15px;
}

.hacker-title {
  color: limegreen;
  font-size: 22px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.myname {
  font-size: 20px;
  font-weight: 600;
}

.about-btn {
  background-color: limegreen;
  color: #fff;
  padding: 0.7rem 1.2rem;
  border: none;
  outline: none;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.about-btn:hover {
  transform: scale(1.05);
  color: #fff;
}

.about-btn:active {
  transform: scale(0.95);
}

.additional-title {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 5px;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .home-container {
    min-height: 74vh;
  }
  .about-btn {
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
  }
  .content-container {
    flex-direction: column;
  }

  .text-container {
    width: 100%;
    margin-bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  .hacker-text {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .text-container {
    left: 50%; /* Center text horizontally */
    top: 50%; /* Adjust vertical position */
    transform: translate(-50%, -50%);
    padding: 10px;
    margin-bottom: 0;
  }
  .additional-title {
    font-size: 22px;
  }
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: limegreen;
}

.social-icons .icon {
  font-size: 24px;
  margin: 0 10px;
  color: #fff;
  transition: transform 0.2s ease;
}

.social-icons .icon:hover {
  transform: scale(1.2);
}
