.cert-container {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(300px, 1fr)
    ); /* Adjust the minmax width as needed */
    gap: 20px; /* Adjust the gap between cards */
    justify-content: center;
    margin-top: 10px;
    margin-left: 40px;
  }