.skill-container {
    width: 100%;
    max-width: 500px; /* Adjust the max-width */
    background: #000814;
    margin: 0 auto; /* Center the container */
    padding: 10px; /* Adjust padding */
    border-radius: 7px;
    border: 1px solid limegreen;
    user-select: none;
  }
  
  /* Add your existing Skills styles here */
  
  
  .skill-container .skill-box {
    width: 100%;
    margin: 25px 0;
  }
  
  .skill-box .skill-title {
    display: block;
    font-size: 14px;
    /* font-weight: 600; */
    color: lime;
  }
  
  .skill-box .skill-bar {
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background: #fff;
  }
  
  .skill-bar .skill-per {
    position: relative;
    display: block;
    height: 100%;
    width: 90%;
    border-radius: 6px;
    background: limegreen;
    animation: progress 0.4s ease-in-out forwards;
    opacity: 0;
  }

  .lime{
    color: limegreen;
  }
  
  .skill-per.java{
    width: 85%;
    animation-delay: 0.1s;
  }

  .skill-per.python{
    width: 75%;
    animation-delay: 0.1s;
  }

  .skill-per.react{
    width: 90%;
    animation-delay: 0.2s;
  }
  
  .skill-per.javascript {
    /*progreso de las diferentes lenguajes*/
    width: 80%;
    animation-delay: 0.2s;
  }
  
  .skill-per.nodejs {
    /*progreso de las diferentes lenguajes*/
    width: 80%;
    animation-delay: 0.3s;
  }
  
  .skill-per.django {
    /*progreso de las diferentes lenguajes*/
    width: 70%;
    animation-delay: 0.3s;
  }
  
  .skill-per.mysql {
    /*progreso de las diferentes lenguajes*/
    width: 75%;
    animation-delay: 0.3s;
  }
  
  @keyframes progress {
    0% {
      width: 0;
      opacity: 1;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .skill-per .tooltip {
    position: absolute;
    right: -14px;
    top: -28px;
    font-size: 11px;
    font-weight: 800;
    color: #fff;
    padding: 2px 6px;
    border-radius: 3px;
    background: limegreen;
  
    z-index: 1;
  }
  
  .tooltip::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -2px;
    height: 10px;
    width: 10px;
    z-index: -1;
    background: limegreen;
    transform: translateX(-50%) rotate(45deg);
  }

  .skill-heading{
    font-size: 25px;
    color: #ededed;
    text-align: center;
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 768px) {
    .skill-container {
      max-width: 90%; /* Adjust the width */
    }
  }
  