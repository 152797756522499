.projectsContainer {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(320px, 1fr)
  ); /* Adjust the minmax width as needed */
  gap: 20px; /* Adjust the gap between cards */
  justify-content: center;
  margin-left: 40px;
}

.project-heading {
  font-size: 30px;
  color: #ededed;
  text-align: center;
  margin-bottom: 20px;
}
